<template>
  <div class="px-4 pb-0 pt-1 mt-2  rounded shadow-inner">
    <div>
      <span class="font-semibold">{{ `${ccp.person.first_name} ${ccp.person.surname}` }}</span>
      <div class="text-gray-400 float-right mt-1 cursor-pointer">
        <font-awesome-icon icon="edit" @click="showEditPerson = true" class="hover:text-blue-500 mr-2" title="Edytuj" />
        <font-awesome-icon icon="trash-alt" @click="del" class="hover:text-red-500" title="Usuń osobe kontaktową" />
      </div>
    </div>
    <p>
      <span v-if="ccp.person.phone" class="mr-3">
        <a :href="`tel:${ccp.person.phone}`">
          <font-awesome-icon icon="phone"/>&nbsp;
          <span v-if="Number(ccp.person.regional_phone) === 1">{{ ccp.person.phone | phoneNo}}</span>
          <span v-else>{{ ccp.person.phone }} <span class="text-xs text-gray-500"><em>zagraniczny</em></span></span>
        </a>
      </span>
      <span><a :href="`mailto:${ccp.person.email}`"><font-awesome-icon icon="at"/> {{ ccp.person.email }}</a></span>
    </p>
    <div v-if="!ccp.person.user" class="flex">
      <span class="text-gray-500 text-sm mr-2">brak użytkownika</span>
      <div @click="showAddUser = true" class="text-gray-400 hover:text-blue-600 text-sm cursor-pointer" title="Dodaj dane logowania">
        <font-awesome-icon icon="plus" />
        <font-awesome-icon icon="laptop-code" />
      </div>
    </div>
    <div v-else>
      <div class="flex">
        <div class="mr-3">
          <font-awesome-icon icon="laptop-code" title="dane logowania" class="mr-1" :class="{'text-green-500': userStatus === 0, 'text-yellow-500': userStatus === 1, 'text-red-500': userStatus === 2}" />
          <span class="text-gray-500 text-sm">login: </span>{{ ccp.person.user.name }}
          <span v-if="ccp.person.email !== ccp.person.user.email"><span class="text-gray-500 text-xs">email (reset hasła): </span>{{ ccp.person.user.email }}</span>
        </div>
        <div class="text-gray-400 text-sm cursor-pointer">
          <font-awesome-icon icon="edit" @click="showEditUser = true" class="hover:text-blue-500 mr-2" title="Edytuj dane logowania" />
          <font-awesome-icon v-if="userStatus === 0" icon="user-lock" @click="blockUser(1)" class="hover:text-yellow-500 mr-2" title="Zablokuj użytkownika" />
          <font-awesome-icon v-if="userStatus === 1" icon="user-lock" @click="blockUser(0)" class="hover:text-green-500 mr-2" title="Odblokuj użytkownika" />
          <font-awesome-icon v-if="userStatus === 0 || userStatus === 1" icon="user-times" @click="disableUser(1)" class="hover:text-red-500" title="Wyłącz użytkownika" />
          <font-awesome-icon v-if="userStatus === 2" icon="user-times" @click="disableUser(0)" class="hover:text-green-500" title="Włącz użytkownika" />
        </div>
      </div>
    </div>
    <UserAdd v-if="showAddUser" :person="ccp.person" @close="showAddUser = false" @added="userChanged" />
    <UserEdit v-if="showEditUser" :user="ccp.person.user" @close="showEditUser = false" @changed="userChanged" />
    <PersonEdit
      v-if="showEditPerson"
      @close="showEditPerson = false"
      @changed="$emit('changed')"
      :person="ccp.person"
    ></PersonEdit>
  </div>
</template>

<script>
import axios from 'axios';
import UserService from '../services/UserService';
import UserAdd from './UserAdd.vue';
import UserEdit from './UserEdit.vue';
import PersonEdit from './PersonEdit.vue';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';

export default {
  name: 'ContractorContactPerson',
  components: {
    UserAdd,
    UserEdit,
    PersonEdit,
  },
  mixins: [errorHandler, confirm],
  props: {
    ccp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAddUser: false,
      showEditUser: false,
      showEditPerson: false,
    };
  },
  computed: {
    userStatus() {
      let status = -1;
      if (this.ccp.person.user) {
        if (Number(this.ccp.person.user.disabled) === 1) {
          status = 2;
        } else if (Number(this.ccp.person.user.disabled) === 0 && Number(this.ccp.person.user.blocked) === 1) {
          status = 1;
        } else if (Number(this.ccp.person.user.disabled) === 0 && Number(this.ccp.person.user.blocked) === 0) {
          status = 0;
        }
      }
      return status;
    },
  },
  methods: {
    del() {
      this.confirm(() => this.deleteCcp(), 'Czy na pewno usunąć osobę kontaktową?');
    },
    deleteCcp() {
      axios.delete(`${process.env.VUE_APP_API_URL}/contractor-contact-person/${this.ccp.id}`)
        .then(() => this.$emit('changed'))
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
    userChanged(user) {
      const changedCcp = {};
      Object.assign(changedCcp, this.ccp);
      changedCcp.user = user;
      this.$emit('changed', changedCcp);
    },
    blockUser(val) {
      this.confirm(() => this.updateUser({ blocked: val }), `Czy na pewno ${val === 0 ? 'odblokować' : 'zablokować'} użytkownika?`);
    },
    disableUser(val) {
      this.confirm(() => this.updateUser({ disabled: val }), `Czy na pewno ${val === 0 ? 'włączyć ponownie' : 'wyłączyć'} konto użytkownika?`);
    },
    updateUser(query) {
      UserService.updateUser(this.ccp.person.user.id, query)
        .then(response => {
          this.userChanged(response.data.result);
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
  },
};
</script>

<style>

</style>
