<template>
  <div>
    <BaseModal
      :activeModal="show"
      @closeModal="close"
      :cancel="false"
      :ok="false"
      :close="true"
      size="xl"
    >
      <template #header>
        Karta zleceniodawcy
        <font-awesome-icon v-if="Number(contractor.disabled) === 1" icon="building" size="sm" class="mt-1 mr-1 text-gray-400" title="wyłączony"/>
        <font-awesome-icon v-else icon="building" size="sm" class="mt-1 mr-1 text-green-400" title="aktywny"/>
      </template>
      <template #default>
        <div v-if="isBusy" class="w-full p-4 h-full text-center">
          <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
        </div>
        <div v-else class="flex flex-wrap">
          <div class="flex flex-col w-full sm:w-1/2 mb-2 sm:pr-3"> <!-- left block wrapper-->

            <div class="relative w-full mb-3"> <!-- contractor block-->
              <MicroMenu>
                <template #default>
                  <ul>
                    <li><a href="" @click.prevent="showEditContractor = true"><font-awesome-icon icon="edit" class="text-blue-500 mr-1" /> edytuj</a></li>
                    <li v-if="Number(contractor.disabled) === 0"><a href="" @click.prevent="disableContractor(1)"><font-awesome-icon icon="building" class="text-red-500 mr-1" /> wyłącz</a></li>
                    <li v-else><a href="" @click.prevent="disableContractor(0)"><font-awesome-icon icon="building" class="text-green-500 mr-1" /> włącz</a></li>
                  </ul>
                </template>
              </MicroMenu>
              <h1 class="font-semibold leading-7 text-gray-600">Zleceniodawca</h1>
              <hr>
              <div class="mt-0">
                <p class="text-xl font-semibold">{{ contractor.company.name }}</p>
                <p class="text-sm text-gray-600">Nazwa skrócona: {{ contractor.company.shortname }}</p>
                <p v-show="contractor.company.nip || contractor.company.regon">
                  <span v-if="contractor.company.nip" class="mr-3"><span class="text-gray-500">NIP:</span> {{contractor.company.nip}}</span>
                  <span v-if="contractor.company.regon"><span class="text-gray-500">REGON:</span> {{contractor.company.regon}}</span>
                </p>
                <div class="flex flex-wrap justify-start">
                  <span class="mr-3"><font-awesome-icon icon="phone" />&nbsp;
                    <a v-if="contractor.company.phone" :href="`tel:${contractor.company.phone}`">
                      <span v-if="Number(contractor.company.regional_phone) === 1">{{ contractor.company.phone | phoneNo}}</span>
                      <span v-else>{{ contractor.company.phone }} <span class="text-xs text-gray-500"><em>zagraniczny</em></span></span>
                    </a>
                    <span v-else class="text-gray-500 text-xs">nie podano</span>
                  </span>
                  <span><font-awesome-icon icon="at" />&nbsp;
                    <a v-if="contractor.company.email" :href="`mailto:${contractor.company.email}`">{{ contractor.company.email }}</a>
                    <span v-else class="text-gray-500 text-xs">nie podano</span>
                  </span>
                </div>
                <p>
                  <span v-if="contractor.company.address1">
                    {{ contractor.company.address1.street }} {{ contractor.company.address1.house_apt_no }};
                    {{ contractor.company.address1.postal_code }} {{ contractor.company.address1.city }}<br>
                    <span class="text-sm text-gray-500">region:</span> {{ contractor.company.address1.region ? contractor.company.address1.region.name : '' }}
                  </span>
                </p>
                <div v-if="contractor.company.bank || contractor.company.bank_acct_no">
                  <p><span class="text-gray-500">bank:</span> {{ contractor.company.bank }}</p>
                  <p><span class="text-gray-500">konto nr:</span> {{ contractor.company.bank_acct_no }}</p>
                </div>
              </div>
            </div> <!-- end of employee block -->

          </div> <!-- end of left block wrapper -->

          <div class="flex flex-col w-full sm:w-1/2 sm:pl-3">  <!-- right block wrapper -->

            <div class="relative w-full mb-3"> <!-- contact-persons block-->
              <div @click="showAddContactPerson = true" class="absolute top-0 right-0 mt-1 px-2 mr-1 cursor-pointer text-blue-500 hover:text-blue-600" title="Dodaj osobę kontaktową">
                <font-awesome-icon icon="user-plus" />
              </div>
              <h1 class="font-semibold leading-7 text-gray-600">Osoby kontaktowe</h1>
              <hr>
              <ContractorContactPerson
                v-for="ccp in contractor.contractorContactPeople"
                :key="ccp.id"
                :ccp="ccp"
                @changed="contractorContactPersonChanged"
              />
              <p v-if="contractor.contractorContactPeople.length === 0">Brak osób kontaktowych</p>
            </div> <!-- end of contact-persons block -->

          </div> <!-- end of right block wrapper -->
        </div>
      </template>
    </BaseModal>
    <ContractorEdit
      v-if="showEditContractor"
      @close="showEditContractor = false"
      @changed="contractorChanged"
      :contractor="contractor"
    ></ContractorEdit>
    <ContractorContactPersonAdd
      v-if="showAddContactPerson"
      @close="showAddContactPerson = false"
      @added="getContractor"
      :contractor="contractor"
    ></ContractorContactPersonAdd>
  </div>
</template>

<script>
import ContractorService from '../services/ContractorService';
import MicroMenu from '../components/MicroMenu.vue';
import ContractorEdit from '../components/ContractorEdit.vue';
import ContractorContactPerson from '../components/ContractorContactPerson.vue';
import ContractorContactPersonAdd from '../components/ContractorContactPersonAdd.vue';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';

export default {
  name: 'ContractorItem',
  components: {
    MicroMenu,
    ContractorEdit,
    ContractorContactPerson,
    ContractorContactPersonAdd,
  },
  mixins: [errorHandler, confirm],
  data() {
    return {
      show: false,
      showEditContractor: false,
      showAddContactPerson: false,
      isBusy: true,
      contractor: {
        company: {
          address1: {},
        },
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.show = true;
    this.getContractor();
  },
  methods: {
    getContractor() {
      ContractorService.getContractor(this.id)
        .then(response => {
          this.contractor = response.data.result;
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error); // method from mixin
        });
    },
    contractorChanged(contractor) {
      console.log(contractor);
      this.contractor = contractor;
      this.$store.dispatch('updates/contractorListNeedsUpdate');
    },
    disableContractor(val) {
      this.confirm(() => this.updateContractor({ disabled: val }), `Czy na pewno ${val === 0 ? 'aktywować ponownie' : 'wyłączyć'} zleceniodawcę?`);
    },
    close() {
      const { query } = this.$route;
      this.$router.push({ name: 'contractors', query });
    },
    updateContractor(query) {
      this.isBusy = true;
      ContractorService.updateContractor(this.id, query)
        .then(response => {
          this.contractor = response.data.result;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
          this.isBusy = false;
          this.$store.dispatch('updates/contractorListNeedsUpdate');
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    contractorContactPersonChanged() {
      this.getContractor();
    },
  },
  watch: {
    $route(to, from) {
      if (JSON.stringify(to.params) !== JSON.stringify(from.params)) {
        this.getContractor();
      }
    },
  },
};
</script>
