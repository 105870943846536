<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Dodawanie osoby kontaktowej
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="mb-4 w-1/3 px-1">
          <label>imię</label>
          <Autocomplete
            resource="forenames"
            :inputClass="errors['person.first_name'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.person_id !== ''"
            v-model="form.person.first_name"
          />
          <p v-if="errors['person.first_name'] !== undefined" class="text-red-500 text-sm">{{ errors['person.first_name'] }}</p>
        </div>
        <div class="mb-4 w-2/3 px-1">
          <label >nazwisko</label>
          <input :class="{ 'border-red-300': errors['person.surname'] !== undefined }" v-model="form.person.surname" type="text" :disabled="form.person_id !== ''">
          <p v-if="errors['person.surname'] !== undefined" class="text-red-500 text-sm">{{ errors['person.surname'] }}</p>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>email</label>
          <Autocomplete
            resource="duplicated-people-by-email"
            :inputClass="errors['person.email'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.person_id !== ''"
            v-model="form.person.email"
            @selected="setPerson"
          />
          <a v-if="form.person_id !== ''" href="#" @click.prevent="clearForm">wyczyść formularz</a>
          <p v-if="errors['person.email'] !== undefined" class="text-red-500 text-sm">{{ errors['person.email'] }}</p>
        </div>
        <div class="mb-4 w-5/12 px-1">
          <label>telefon</label>
          <input :class="{ 'border-red-300': errors['person.phone'] !== undefined }" v-model="form.person.phone" type="text" :disabled="form.person_id !== ''">
          <p v-if="errors['person.phone'] !== undefined" class="text-red-500 text-sm">{{ errors['person.phone'] }}</p>
        </div>
        <div class="mb-4 w-1/12 px-1 text-center">
          <label>PL</label>
          <input v-model="form.person.regional_phone" true-value="1" false-value="0" type="checkbox" :disabled="form.person_id !== ''">
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { errorHandler } from '../mixins/errorHandler';
import Autocomplete from './Autocomplete.vue';

export default {
  name: 'ContractorContactPersonAdd',
  mixins: [errorHandler],
  components: { Autocomplete },
  props: {
    contractor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        contractor_id: this.contractor.id,
        person_id: '',
        person: {
          first_name: '',
          surname: '',
          email: '',
          phone: '',
          regional_phone: '1',
        },
      },
      errors: {},
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    save() {
      axios.post(`${process.env.VUE_APP_API_URL}/contractor-contact-person`, this.form)
        .then(response => {
          const newCcp = response.data.result;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Osoba kontaktowa została dodana...',
          });
          this.$emit('added', newCcp);
          this.show = false;
        })
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
    setPerson(payload) {
      this.form.person = {
        first_name: payload.first_name,
        surname: payload.surname,
        email: payload.email ? payload.email : payload.name,
        phone: payload.phone,
        regional_phone: payload.regional_phone,
      };
      this.form.person_id = payload.id;
    },
    clearForm() {
      this.form = {
        contractor_id: this.contractor.id,
        person_id: '',
        person: {
          first_name: '',
          surname: '',
          email: '',
          phone: '',
          regional_phone: '1',
        },
      };
    },
  },
};
</script>
