<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Edycja danych zleceniodawcy
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="mb-4 w-2/3 px-1">
          <label>nazwa</label>
          <input :class="{ 'border-red-300': errors.name !== undefined }" v-model="form.name" type="text">
          <p v-if="errors.name !== undefined" class="text-red-500 text-sm">{{ errors.name }}</p>
        </div>
        <div class="mb-4 w-1/3 px-1">
          <label>nazwa skr.</label>
          <input :class="{ 'border-red-300': errors.shortname !== undefined }" v-model="form.shortname" type="text">
          <p v-if="errors.shortname !== undefined" class="text-red-500 text-sm">{{ errors.shortname }}</p>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>nip</label>
          <input :class="{ 'border-red-300': errors.nip !== undefined }" v-model="form.nip" type="text">
          <p v-if="errors.nip !== undefined" class="text-red-500 text-sm">{{ errors.nip }}</p>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>regon</label>
          <input :class="{ 'border-red-300': errors.regon !== undefined }" v-model="form.regon" type="text">
          <p v-if="errors.regon !== undefined" class="text-red-500 text-sm">{{ errors.regon }}</p>
        </div>
        <div class="mb-4 w-11/12 md:w-5/12 px-1">
          <label>telefon</label>
          <input :class="{ 'border-red-300': errors.phone !== undefined }" v-model="form.phone" type="text">
          <p v-if="errors.phone !== undefined" class="text-red-500 text-sm">{{ errors.phone }}</p>
        </div>
        <div class="mb-4 w-1/12 px-1 text-center">
          <label>PL</label>
          <input v-model="form.regional_phone" true-value="1" false-value="0" type="checkbox">
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>email kontakt.</label>
          <input :class="{ 'border-red-300': errors.email !== undefined }" v-model="form.email" type="email">
          <p v-if="errors.email !== undefined" class="text-red-500 text-sm">{{ errors.email }}</p>
        </div>
        <hr class="w-full">
        <div class="mb-1 w-full px-1">
          <label>
            <input v-model="form.address.local_address" true-value="1" false-value="0" class="w-auto" type="checkbox"> Polska
          </label>
        </div>
        <div class="mb-4 w-3/5 md:w-1/2 px-1">
          <label>ulica</label>
          <input :class="{ 'border-red-300': errors['address.street'] !== undefined }" v-model="form.address.street" type="text">
          <p v-if="errors['address.street'] !== undefined" class="text-red-500 text-sm">{{ errors['address.street'] }}</p>
        </div>
        <div class="mb-4 w-2/5 md:w-1/2 px-1">
          <label>nr domu/mieszk.</label>
          <input :class="{ 'border-red-300': errors['address.house_apt_no'] !== undefined }" v-model="form.address.house_apt_no" type="text">
          <p v-if="errors['address.house_apt_no'] !== undefined" class="text-red-500 text-sm">{{ errors['address.house_apt_no'] }}</p>
        </div>
        <div class="mb-4 w-1/4 md:w-1/2 px-1">
          <label>kod poczt.</label>
          <Autocomplete
            resource="postal-codes"
            :inputClass="errors['address.postal_code'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.fake_person === '1'"
            v-model="form.address.postal_code"
            @selected="setCity"
          />
          <p v-if="errors['address.postal_code'] !== undefined" class="text-red-500 text-sm">{{ errors['address.postal_code'] }}</p>
        </div>
        <div class="mb-4 w-3/4 md:w-1/2 px-1">
          <label>miasto</label>
          <Autocomplete
            resource="cities"
            :inputClass="errors['address.city'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.fake_person === '1'"
            v-model="form.address.city"
          />
          <p v-if="errors['address.city'] !== undefined" class="text-red-500 text-sm">{{ errors['address.city'] }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>poczta</label>
          <Autocomplete
            resource="cities"
            :inputClass="errors['address.post_city'] !== undefined ? 'border-red-300' : ''"
            :disabledInput="form.fake_person === '1'"
            v-model="form.address.post_city"
          />
          <p v-if="errors['address.post_city'] !== undefined" class="text-red-500 text-sm">{{ errors['address.post_city'] }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>region</label>
          <select v-model="form.address.region_id">
            <option value=""></option>
            <option v-for="region in regions" :key="region.id" :value="region.id">{{ region.name }}</option>
          </select>
          <p v-if="errors['address.region_id'] !== undefined" class="text-red-500 text-sm">{{ errors['address.region_id'] }}</p>
        </div>
        <div v-show="form.local_address === '0'" class="mb-4 w-full px-1">
          <label>państwo</label>
          <input :class="{ 'border-red-300': errors['address.country'] !== undefined }" v-model="form.address.country" type="text">
          <p v-if="errors['address.country'] !== undefined" class="text-red-500 text-sm">{{ errors['address.country'] }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>bank</label>
          <input :class="{ 'border-red-300': errors.bank !== undefined }" v-model="form.bank" type="text">
          <p v-if="errors.bank !== undefined" class="text-red-500 text-sm">{{ errors.bank }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>nr konta</label>
          <input :class="{ 'border-red-300': errors.bank_acct_no !== undefined }" v-model="form.bank_acct_no" type="text">
          <p v-if="errors.bank_acct_no !== undefined" class="text-red-500 text-sm">{{ errors.bank_acct_no }}</p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import ContractorService from '@/services/ContractorService';
import { errorHandler } from '../mixins/errorHandler';
import Autocomplete from './Autocomplete.vue';

export default {
  name: 'ContractorEdit',
  mixins: [errorHandler],
  components: { Autocomplete },
  props: {
    contractor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      company: this.contractor.company,
      form: {
        name: '',
        shortname: '',
        nip: '',
        regon: '',
        phone: '',
        regional_phone: '',
        email: '',
        address: {
          local_address: '1',
          street: '',
          house_apt_no: '',
          postal_code: '',
          city: '',
          post_city: '',
          region_id: '',
          country: '',
        },
        bank: '',
        bank_acct_no: '',
      },
      errors: {},
      show: false,
    };
  },
  computed: {
    regions() {
      return this.$store.getters['regions/regions'];
    },
  },
  mounted() {
    if (this.regions.length === 0) {
      this.$store.dispatch('regions/get');
    }
    this.setForm();
    this.show = true;
  },
  methods: {
    save() {
      this.errors = {};
      ContractorService.updateContractor(this.contractor.id, this.form)
        .then(response => {
          this.$emit('changed', response.data.result);
          this.show = false;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
        })
        .catch(error => {
          this.errors = this.resolveError(error);
        });
    },
    setCity(payload) {
      this.form.address.city = payload.city;
    },
    setForm() {
      Object.keys(this.form).forEach(field => {
        if (field === 'address') {
          if (this.company.address1_id) {
            Object.keys(this.form.address).forEach(attr => {
              this.form.address[attr] = this.company.address1[attr] !== null && this.company.address1[attr] !== undefined ? this.company.address1[attr].toString() : '';
            });
          } else {
            this.form.address.local_address = '1';
          }
        } else {
          this.form[field] = this.company[field] !== null ? this.company[field].toString() : '';
        }
      });
    },
  },
};
</script>
